var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.reloadKey},[_c('dashboard-page-title',{attrs:{"showSearch":false,"showFilter":false,"showMainActions":false},on:{"addEvent":function($event){return _vm.$router.push({ name: 'addMerchant' })}}},[_vm._v(" الإعدادات ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [(Object.keys(_vm.clinicData).length)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"10"}},[_c('div',[_c('b-card',{staticClass:"iq-border-radius-10 mb-4",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"text-primary"},[_vm._v("البيانات الشخصية")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('img',{staticClass:"img-fluid rounded-circle settings-user-profile",attrs:{"src":_vm.clinicData.logo}})]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('input-form',{staticClass:"mb-3 joining-label",attrs:{"validate":"required","name":"name","placeholder":"اسم العيادة","label":"اسم العيادة"},model:{value:(_vm.clinicData.clinic_name),callback:function ($$v) {_vm.$set(_vm.clinicData, "clinic_name", $$v)},expression:"clinicData.clinic_name"}})],1),_c('b-col',{attrs:{"xl":"6"}},[_c('country-code',{attrs:{"defaultCountry":_vm.clinicData.country_code === '002' ? 'eg' : 'sa',"placeholder":"رقم الهاتف","label":"رقم الهاتف","name":"phone","id":"phone-code-primary_phone_number","validate":{
                          required: true,
                          numeric: true,
                          regex: /^\d{9,11}$/,
                        },"disabled":""},on:{"onSelect":_vm.onSelect},model:{value:(_vm.clinicData.primary_phone_number),callback:function ($$v) {_vm.$set(_vm.clinicData, "primary_phone_number", $$v)},expression:"clinicData.primary_phone_number"}})],1),_c('b-col',{attrs:{"xl":"6"}},[_c('input-form',{staticClass:"mb-3 joining-label customize-height-clinic",attrs:{"validate":'required|email',"name":"id","placeholder":"Mahmoudsayed@gmail.com","disabled":"","label":"البريد الالكترونى"},model:{value:(_vm.clinicData.email),callback:function ($$v) {_vm.$set(_vm.clinicData, "email", $$v)},expression:"clinicData.email"}}),_c('p',[_vm._v(" لتعديل رقم الهاتف أو البريد الإلكتروني برجاء التوجه "),_c('a',{attrs:{"href":_vm.VUE_APP_PROFILE_URL}},[_c('span',{staticClass:"text-warning"},[_vm._v("للملف الشخصي")])])])],1),_c('b-col',{attrs:{"xl":"6"}},[_c('country-code',{attrs:{"defaultCountry":_vm.clinicData.country_code === '002' ? 'eg' : 'sa',"placeholder":"رقم هاتف اخر","label":"رقم هاتف اخر","name":"phone2","id":"phone-code-primary_phone_number","validate":{
                          required: false,
                          numeric: true,
                          regex: /^\d{9,11}$/,
                        }},on:{"onSelect":_vm.onSelect2},model:{value:(_vm.clinicData.secondary_phone_number),callback:function ($$v) {_vm.$set(_vm.clinicData, "secondary_phone_number", $$v)},expression:"clinicData.secondary_phone_number"}})],1),_c('b-col',{staticClass:"edit",attrs:{"md":"3"}},[_c('label',{staticClass:"joining-label"},[_vm._v("المدينة")]),_c('main-select',{staticClass:"mb-3 joining-label-clinic customize-height-clinic",style:({
                          'font-size': '14px',
                          'font-weight': 'normal',
                        }),attrs:{"name":"المدينة","label":"المدينة","placeholder":"المدينة","dir":"rtl","options":_vm.allCityOptions},model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}})],1),_c('b-col',{attrs:{"md":"9"}},[_c('input-form',{staticClass:"mb-3 joining-label",attrs:{"validate":'required',"name":"address","placeholder":"ادخل عنوان العيادة","label":"عنوان العيادة"},model:{value:(_vm.clinicData.clinic_address),callback:function ($$v) {_vm.$set(_vm.clinicData, "clinic_address", $$v)},expression:"clinicData.clinic_address"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('input-form',{staticClass:"mb-3 joining-label",attrs:{"name":"description","placeholder":"اكتب نبذة مختصرة عن العيادة","label":"الوصف"},model:{value:(_vm.clinicData.description),callback:function ($$v) {_vm.$set(_vm.clinicData, "description", $$v)},expression:"clinicData.description"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('input-form',{staticClass:"mb-3 joining-label",attrs:{"validate":{
                          required: true,
                          regex: /^['SA']{2}[0-9]{22}$/,
                        },"name":"iban","placeholder":"ادخل ال IBAN","label":"رقم IBAN"},model:{value:(_vm.ibanInput),callback:function ($$v) {_vm.ibanInput=$$v},expression:"ibanInput"}})],1)],1)],1)],1)],1)],1),(_vm.clinicData.status === 'pending')?_c('div',[_c('b-row',_vm._l((_vm.requiredDocuments),function(itemFile,index){return _c('b-col',{key:index,staticClass:"mb-4",attrs:{"md":"4"}},[_c('img-upload-box',{attrs:{"data":itemFile,"index":index,"src":itemFile.url,"backgroundImage":itemFile.url},on:{"uploadDocument":_vm.uploadDocument}})],1)}),1)],1):(_vm.clinicData.status === 'processing')?_c('div',[_c('b-row',_vm._l((_vm.clinicData.uploaded_documents
                  .length > 0
                  ? _vm.clinicData.uploaded_documents
                  : _vm.requiredDocuments),function(itemFile,index){return _c('b-col',{key:index,staticClass:"mb-4",attrs:{"md":"4"}},[_c('img-upload-box',{attrs:{"data":itemFile.doc || itemFile,"index":index,"src":itemFile.url},on:{"uploadDocument":_vm.uploadDocument}})],1)}),1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.loadingButtonSubmit)?_c('b-button',{staticClass:"vete-save-btn m-auto",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" حفظ ")]):_c('b-button',{staticClass:"vete-save-btn m-auto",attrs:{"variant":"primary"}},[_c('spinner-loading',{attrs:{"text":'يتم التحميل'}})],1)],1)])],1)],1):_c('b-row',{staticClass:"justify-content-center"},[_c('b-spinner',{attrs:{"label":"loading..."}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }