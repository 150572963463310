<template>
  <div :key="reloadKey">
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      الإعدادات
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        v-if="Object.keys(clinicData).length"
      >
        <b-row>
          <b-col md="10">
            <div>
              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">البيانات الشخصية</h5>
                </template>
                <b-row>
                  <b-col lg="3">
                    <img
                      :src="clinicData.logo"
                      class="img-fluid rounded-circle settings-user-profile"
                    />
                  </b-col>
                  <b-col lg="9">
                    <b-row>
                      <b-col xl="6">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="clinicData.clinic_name"
                          validate="required"
                          name="name"
                          placeholder="اسم العيادة"
                          label="اسم العيادة"
                        />
                      </b-col>
                      <b-col xl="6">
                        <country-code
                          :defaultCountry="
                            clinicData.country_code === '002' ? 'eg' : 'sa'
                          "
                          placeholder="رقم الهاتف"
                          label="رقم الهاتف"
                          name="phone"
                          v-model="clinicData.primary_phone_number"
                          id="phone-code-primary_phone_number"
                          :validate="{
                            required: true,
                            numeric: true,
                            regex: /^\d{9,11}$/,
                          }"
                          disabled
                          @onSelect="onSelect"
                        />
                      </b-col>
                      <b-col xl="6">
                        <input-form
                          class="mb-3 joining-label customize-height-clinic"
                          v-model="clinicData.email"
                          :validate="'required|email'"
                          name="id"
                          placeholder="Mahmoudsayed@gmail.com"
                          disabled
                          label="البريد الالكترونى"
                        />
                        <p>
                          لتعديل رقم الهاتف أو البريد الإلكتروني برجاء التوجه
                          <a :href="VUE_APP_PROFILE_URL"
                            ><span class="text-warning">للملف الشخصي</span></a
                          >
                        </p>
                      </b-col>
                      <b-col xl="6">
                        <country-code
                          :defaultCountry="
                            clinicData.country_code === '002' ? 'eg' : 'sa'
                          "
                          placeholder="رقم هاتف اخر"
                          label="رقم هاتف اخر"
                          name="phone2"
                          v-model="clinicData.secondary_phone_number"
                          id="phone-code-primary_phone_number"
                          :validate="{
                            required: false,
                            numeric: true,
                            regex: /^\d{9,11}$/,
                          }"
                          @onSelect="onSelect2"
                        />
                      </b-col>
                      <b-col md="3" class="edit">
                        <label class="joining-label">المدينة</label>
                        <main-select
                          class="mb-3 joining-label-clinic customize-height-clinic"
                          v-model="selectedCity"
                          name="المدينة"
                          label="المدينة"
                          placeholder="المدينة"
                          dir="rtl"
                          :options="allCityOptions"
                          :style="{
                            'font-size': '14px',
                            'font-weight': 'normal',
                          }"
                        />
                      </b-col>
                      <b-col md="9">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="clinicData.clinic_address"
                          :validate="'required'"
                          name="address"
                          placeholder="ادخل عنوان العيادة"
                          label="عنوان العيادة"
                        />
                      </b-col>
                      <b-col md="12">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="clinicData.description"
                          name="description"
                          placeholder="اكتب نبذة مختصرة عن العيادة"
                          label="الوصف"
                        />
                      </b-col>
                      <b-col md="12">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="ibanInput"
                          :validate="{
                            required: true,
                            regex: /^['SA']{2}[0-9]{22}$/,
                          }"
                          name="iban"
                          placeholder="ادخل ال IBAN"
                          label="رقم IBAN"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
              <!-- مواعيد العمل -->
              <!-- <b-card class="iq-border-radius-10 mb-4">
                <div class="" v-if="work_times.length">
                  <h5 class="text-primary">مواعيد العمل</h5>
                  <div class="p-4">
                    <div
                      class="d-flex align-items-center gap_1 time-of-work-item flex-wrap"
                      v-for="(timeOfWork, index) in work_times"
                      :key="index"
                    >
                      <div
                        class="time-of-work-item-controls d-flex align-items-center gap_1"
                      >
                        <span
                          class="time-of-work-item-controls-label font-size-20"
                          >{{ timeOfWork.day }}</span
                        >

                        <b-form-checkbox
                          :id="`time-of-work-item-controls-checkbox-${index}`"
                          v-model="timeOfWork.is_active"
                          :name="`time-of-work-item-controls-checkbox-${index}`"
                          class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                          :value="false"
                          :unchecked-value="true"
                          ariaDescribedby="item-checkbox-day"
                        >
                          مغلق
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="timeOfWork.is_active"
                        class="d-flex flex-column gap_1"
                      >
                        <div
                          class="d-flex align-items-center gap_4 flex-wrap"
                          v-for="(timeItem, idx) in timeOfWork.working_periods"
                          :key="idx"
                        >
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              من
                            </h6>
                            <flat-pickr
                              v-model="timeItem.from"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date"
                              :class="{
                                'is-invalid': hasTimeOverlap(index, idx),
                              }"
                              @input="onTimeChange(index, idx, timeItem.from)"
                            />
                            <div
                              v-if="hasTimeOverlap(index, idx)"
                              class="invalid-feedback d-block"
                            >
                              يجب أن تبدأ فترة العمل التالية بعد نهاية الفترة
                              السابقة.
                            </div>
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              الى
                            </h6>
                            <flat-pickr
                              v-model="timeItem.to"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date-to"
                              :class="{
                                'is-invalid':
                                  hasTimeOverlap(index, idx) ||
                                  !isValidTimeOrder(index, idx),
                              }"
                              @input="onTimeChange(index, idx, timeItem.to)"
                            />
                            <div
                              v-if="
                                hasTimeOverlap(index, idx) ||
                                !isValidTimeOrder(index, idx)
                              "
                              class="invalid-feedback d-block"
                            >
                              <template v-if="hasTimeOverlap(index, idx)">
                                يجب أن تبدأ فترة العمل التالية بعد نهاية الفترة
                                السابقة.
                              </template>
                              <template
                                v-else-if="!isValidTimeOrder(index, idx)"
                              >
                                يجب أن يكون وقت النهاية بعد وقت البدء في نفس
                                فترة العمل.
                              </template>
                            </div>
                          </div>
                          <b-button
                            v-if="idx === timeOfWork.working_periods.length - 1"
                            class="d-flex align-items-center d-flex align-items-center bg-transparent border-0 p-0 time-of-work-item-add-btn"
                            @click="addTimeOfWork(index)"
                          >
                            <i class="las la-clock icon"></i>
                            <span>اضافة فترة عمل اخرى</span>
                          </b-button>
                          <b-button
                            v-if="idx > 0"
                            variant="danger"
                            class="d-flex align-items-center d-flex align-items-center bg-transparent border-0 p-0 time-of-work-item-add-btn"
                            @click="deleteTimeOfWork(index, idx)"
                          >
                            <i class="las la-trash icon"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card> -->
            </div>
            <div v-if="clinicData.status === 'pending'">
              <b-row>
                <b-col
                  md="4"
                  class="mb-4"
                  v-for="(itemFile, index) in requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile"
                    :index="index"
                    @uploadDocument="uploadDocument"
                    :src="itemFile.url"
                    :backgroundImage="itemFile.url"
                  />
                </b-col>
              </b-row>
            </div>
            <div v-else-if="clinicData.status === 'processing'">
              <b-row>
                <b-col
                  md="4"
                  class="mb-4"
                  v-for="(itemFile, index) in clinicData.uploaded_documents
                    .length > 0
                    ? clinicData.uploaded_documents
                    : requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile.doc || itemFile"
                    :index="index"
                    @uploadDocument="uploadDocument"
                    :src="itemFile.url"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                variant="primary"
                class="vete-save-btn m-auto"
                type="submit"
                v-if="!loadingButtonSubmit"
              >
                حفظ
              </b-button>
              <b-button variant="primary" class="vete-save-btn m-auto" v-else>
                <spinner-loading :text="'يتم التحميل'"></spinner-loading>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="justify-content-center" v-else>
        <b-spinner label="loading..."></b-spinner>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import clinicApi from "../services/settings";
import ImgUploadBox from "../../../doctorDashboard/settings/components/imgUploadBox.vue";

export default {
  components: { ImgUploadBox },
  mounted() {
    core.index();
    this.getAllCities();
    this.reloadKey++;
  },
  data() {
    return {
      reloadKey: 0,
      VUE_APP_PROFILE_URL: process.env.VUE_APP_PROFILE_URL,
      allCity: [],
      allCityOptions: [],
      selectedCity: "",
      loadingButtonSubmit: false,
      clinicData: {},
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user
        : {},
      work_times: [],
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: false,
        defaultDate: "2:30",
        static: true
      },
      code: "",
      code2: "",
      requiredDocuments: null,
      uploadedDocuments: [],
      ibanInput: "", // Separate variable for IBAN
    };
  },
  methods: {
    // beforeRouteEnter(to, from, next) {
    //   console.log("hhhhhhhhhhhh");
    //   next((vm) => {
    //     vm.$nextTick(() => {
    //       vm.$forceUpdate();
    //     });
    //   });
    // },

    getAllCities() {
      clinicApi.getAllCities().then((res) => {
        this.allCity = res.data.data;
        this.allCityOptions = this.allCity.map((city) => city.name);
        this.setSelectedCityFromUserData();
      });
    },
    setSelectedCityFromUserData() {
      const storedCity = localStorage.getItem("selectedCity");
      if (storedCity && this.allCityOptions.includes(storedCity)) {
        this.selectedCity = storedCity;
      } else if (
        this.userData &&
        this.userData.city_id &&
        this.allCityOptions.length > 0
      ) {
        const cityName = this.allCity.find(
          (city) => city.id === this.userData.city_id
        )?.name;
        if (cityName) {
          this.selectedCity = cityName;
          localStorage.setItem("selectedCity", cityName);
        }
      }
    },
    // validateWorkingPeriods(workTimeIndex) {
    //   const workingPeriods = this.work_times[workTimeIndex].working_periods;

    //   // Iterate over each working period, starting from the second one
    //   for (let i = 1; i < workingPeriods.length; i++) {
    //     const previousPeriod = workingPeriods[i - 1];
    //     const currentPeriod = workingPeriods[i];

    //     // Convert times to milliseconds for comparison
    //     const previousEndTime = new Date(
    //       `2000-01-01 ${previousPeriod.to}`
    //     ).getTime();
    //     const currentStartTime = new Date(
    //       `2000-01-01 ${currentPeriod.from}`
    //     ).getTime();

    //     // Check if the current period starts before the previous period ends
    //     if (currentStartTime <= previousEndTime) {
    //       return false; // Validation failed
    //     }
    //   }

    //   return true; // Validation passed
    // },
    deleteTimeOfWork(workTimeIndex, timeItemIndex) {
      // Remove the time item from the working periods array
      this.work_times[workTimeIndex].working_periods.splice(timeItemIndex, 1);
      if (this.work_times[workTimeIndex].working_periods.length === 0) {
        this.work_times.splice(workTimeIndex, 1);
      }
    },
    addTimeOfWork(idx) {
      this.work_times[idx].working_periods.push({ from: "", to: "" });
    },
    getClinicInfo() {
      clinicApi.getClinicInfo().then((response) => {
        this.clinicData = response.data;
        this.ibanInput = this.clinicData.IBAN; // Initialize ibanInput
        console.log("this.clinicData", this.clinicData);
      });
    },
    getRequiredDocuments() {
      clinicApi.getRequiredDocuments().then((response) => {
        this.requiredDocuments = response.data.data;
        console.log("this.requiredDocuments", this.requiredDocuments);
      });
    },
    uploadDocument(file) {
      const fileExist = this.uploadedDocuments.find(
        (f) => f.doc_id === file.doc_id
      );
      if (fileExist) {
        const docs = this.uploadedDocuments.filter(
          (item) => item.doc_id === file.doc_id
        );
        this.uploadedDocuments = docs;
      } else {
        this.uploadedDocuments.push(file);
      }
    },
    apiCaller(payload, docs = true) {
      clinicApi
        .updateClinicInfo(payload)
        .then((response) => {
          core.showSnackbar("success", response.data.message);
        })
        .then(() => {
          if (docs) {
            clinicApi
              .updateClinicsDocuments({ documents: this.uploadedDocuments })
              .then((response) => {
                // core.showSnackbar("success", response.data.message);
              });
          }
        })
        .finally(() => {
          this.loadingButtonSubmit = false;
        });
    },
    onSubmit() {
      this.loadingButtonSubmit = true;
      // handle work times
      const workOfTimes = this.work_times.map((item) => {
        if (item.is_active) {
          return item;
        } else {
          return {
            ...item,
            working_periods: [],
          };
        }
      });
      let isValid = true; // Flag to track overall validation
      // for (let i = 0; i < this.work_times.length; i++) {
      //   if (!this.validateWorkingPeriods(i)) {
      //     isValid = false; // Set flag to false if validation fails
      //     core.showSnackbar(
      //       "error",
      //       "يجب أن تبدأ فترة العمل التالية بعد نهاية الفترة السابقة."
      //     );
      //   }
      //   if (!this.work_times[i].is_active) {
      //     continue;
      //   }
      //   for (let j = 0; j < this.work_times[i].working_periods.length; j++) {
      //     if (!this.isValidTimeOrder(i, j)) {
      //       isValid = false;
      //       core.showSnackbar(
      //         "error",
      //         "يجب أن يكون وقت النهاية بعد وقت البدء في نفس فترة العمل."
      //       );
      //     }
      //   }
      // }

      // if (!isValid) {
      //   this.loadingButtonSubmit = false;
      //   return; // Stop submission if any validation fails
      // }
      const payload = {
        clinic_name: this.clinicData.clinic_name,
        description: this.clinicData.description,
        email: this.clinicData.email,
        primary_phone_number: this.clinicData.primary_phone_number,
        secondary_phone_number: this.clinicData.secondary_phone_number,
        clinic_address: this.clinicData.clinic_address,
        // work_times: workOfTimes,
        IBAN: this.ibanInput, // Copy from ibanInput
        city_id: +this.allCity.find((city) => city.name === this.selectedCity)
          .id,
      };
      if (this.clinicData.status !== "accepted") {
        if (
          (this.requiredDocuments &&
            this.requiredDocuments.length === this.uploadedDocuments.length) ||
          this.clinicData.uploaded_documents.length
        ) {
          this.apiCaller({ ...payload, status: "processing" });
          this.$router.push("/hospital/processing");
        } else {
          core.showSnackbar("error", "please upload your all documents");
          this.loadingButtonSubmit = false;
        }
      } else {
        this.apiCaller(payload, false);
        // this.$router.push("/hospital/settings");
      }
    },
    // onTimeChange(workTimeIndex, timeItemIndex, newValue, oldValue) {
    //   // Trigger validation when 'from' or 'to' time changes
    //   if (
    //     newValue !== oldValue &&
    //     this.work_times[workTimeIndex].working_periods.length > 1
    //   ) {
    //     if (
    //       !this.validateWorkingPeriods(workTimeIndex) &&
    //       this.work_times[workTimeIndex].is_active
    //     ) {
    //       this.isError = true;
    //       // Show error message if validation fails
    //     } else {
    //       // Clear error message if validation passes
    //     }
    //   }
    // },
    // Function to check if there is time overlap
    // hasTimeOverlap(workTimeIndex, timeItemIndex) {
    //   if (this.work_times[workTimeIndex].working_periods.length < 2) {
    //     return false; // No overlap if there's only one period
    //   }

    //   const workingPeriods = this.work_times[workTimeIndex].working_periods;
    //   const currentPeriod = workingPeriods[timeItemIndex];

    //   // Iterate over other periods, excluding the current one
    //   for (let i = 0; i < workingPeriods.length; i++) {
    //     if (i === timeItemIndex) {
    //       continue; // Skip the current period
    //     }

    //     const otherPeriod = workingPeriods[i];

    //     // Convert times to milliseconds for comparison
    //     const currentStartTime = new Date(
    //       `2000-01-01 ${currentPeriod.from}`
    //     ).getTime();
    //     const currentEndTime = new Date(
    //       `2000-01-01 ${currentPeriod.to}`
    //     ).getTime();
    //     const otherStartTime = new Date(
    //       `2000-01-01 ${otherPeriod.from}`
    //     ).getTime();
    //     const otherEndTime = new Date(`2000-01-01 ${otherPeriod.to}`).getTime();

    //     // Check if the periods overlap
    //     if (
    //       (currentStartTime >= otherStartTime &&
    //         currentStartTime < otherEndTime) ||
    //       (currentEndTime > otherStartTime && currentEndTime <= otherEndTime) ||
    //       (currentStartTime < otherStartTime && currentEndTime > otherEndTime)
    //     ) {
    //       return true; // Overlap detected
    //     }
    //   }

    //   return false; // No overlap found
    // },
    // Function to check if 'to' time is after 'from' time
    isValidTimeOrder(workTimeIndex, timeItemIndex) {
      const workingPeriods = this.work_times[workTimeIndex].working_periods;
      const currentPeriod = workingPeriods[timeItemIndex];

      // Convert times to milliseconds for comparison
      const fromTime = new Date(`2000-01-01 ${currentPeriod.from}`).getTime();
      const toTime = new Date(`2000-01-01 ${currentPeriod.to}`).getTime();

      return toTime > fromTime; // Return true if 'to' time is after 'from'
    },
    onSelect(data) {
      this.code = data;
    },
    onSelect2(data) {
      this.code2 = data;
    },
  },
  watch: {
    selectedCity(newValue) {
      if (newValue) {
        localStorage.setItem("selectedCity", newValue);
      }
    },
    clinicData(val) {
      if (val) {
        // work times
        // add initial item for inactive days
        if (val.work_times) {
          const newItems = val.work_times.map((item) => {
            if (!item.is_active) {
              if (item.working_periods.length) {
                return item;
              } else {
                return {
                  ...item,
                  working_periods: [{ to: "", from: "" }],
                };
              }
            } else {
              return item;
            }
          });
          this.work_times = newItems;
        } else {
          this.work_times = [
            {
              day: "Saturday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "sunday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Monday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Tuesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Wednesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Thursday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Friday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
          ];
        }
      }
    },
  },
  created() {
    //this.getAllCities();
    this.getClinicInfo();
    this.getRequiredDocuments();
  },
};
</script>
<style>
label {
  font-size: 20px;
}
/* .edit label {
  font-size: 20px;
} */
.edit .mb-3.joining-label-clinic ::placeholder {
  font-size: 10px !important;
  font-weight: normal;
}
.customize-height-clinic .vs__dropdown-menu {
  max-height: 108px !important;
  overflow-y: auto;
}
.customize-height-clinic .vs__dropdown-toggle {
  border-radius: 10px !important;
}
</style>
